import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import logoIcon from 'assets/icons/logo.svg'
import useLocation from 'hooks/useLocation'
import scrollToSection from 'utils/scrollToSection'
import AnchorLink from 'components/shared/AnchorLink'
import { LangContext } from 'contexts/LangContext'

const Wrapper = styled.div`
  display: block;
  width: 152px;
  height: ${({ theme }) => theme.navSize.mobile};
  cursor: pointer;
  padding: 20px 0;
  ${({ theme }) => theme.mq.lg} {
    padding: 10px 0;
    width: 210px;
    height: ${({ theme }) => theme.navSize.desktop};
  }
  ${({ big }) =>
    big &&
    css`
      width: 210px;
      height: 100px;
    `}
`

const InnerWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`

const Logo = ({ big, close }) => {
  const location = useLocation()
  const { lang } = useContext(LangContext)

  return (
    <>
      {location !== '/' && location !== '/en/' ? (
        <Wrapper big={big} onClick={close}>
          <InnerWrapper
            as={AnchorLink}
            to={lang === 'EN' ? '/en/#home' : '/#home'}
            stripHash
          >
            <Image src={logoIcon} alt="logo" />
          </InnerWrapper>
        </Wrapper>
      ) : (
        <Wrapper
          big={big}
          onClick={() => {
            scrollToSection('#home')
            close()
          }}
        >
          <Image src={logoIcon} alt="logo" />
        </Wrapper>
      )}
    </>
  )
}

Logo.propTypes = {
  big: PropTypes.bool,
  close: PropTypes.func,
}

Logo.defaultProps = {
  big: false,
  close: () => null,
}

export default Logo
